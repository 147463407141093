let mainSource = [];
let selectedFolderKey = '';
let canUploadFiles = false;
let canRenameFiles = false;
let canDownloadFiles = false;
let canDeleteFiles = false;

const triggerOnChangeEvent = (type, data) => {
  if (window.onChangeEvent) {
    window.onChangeEvent(type, data);
  }
}

const initializeFileManager = () => {
  loadFolderStructureTable();
  loadFolderContentTable();
  loadUploadPopup();
}

const setPermissions = (canUploadFile, canRenameFile, canDownloadFile, canDeleteFile) => {
  canUploadFiles = true;
  canRenameFiles = canRenameFile;
  canDownloadFiles = canDownloadFile;
  canDeleteFiles = canDeleteFile;
}

const translations = {
  en: {
    newFolder: 'New folder',
    rename: 'Rename',
    delete: 'Delete',
    download: 'Download',
    nodata: 'This folder is empty.'
  },
  nb: {
    newFolder: 'Ny mappe',
    rename: 'Endre navn',
    delete: 'Slett',
    download: 'Last ned',
    nodata :'Denne mappen er tom.'
  },
};

let currentLanguage = 'en';

const updateFolderStructureTable = (source, key = null, lang) => {
  currentLanguage = lang;
  $("#table :ui-fancytree").fancytree("destroy");
  loadFolderStructureTable();
  mainSource = source;
  $('#table').fancytree('option', 'source', source);
  if (!key) { return; }
  let treeNode = $.ui.fancytree.getTree("#table").getNodeByKey(key);
  if (!treeNode || !treeNode.title) {
    return;
  }
  treeNode.setActive();
  treeNode.setExpanded();
  const results = findDocumentsByKey(mainSource, key);
  if (results && results.length && results[0].children && results[0].children.length) { updateFolderContentTable(results[0].children); }
}

const updateFolderContentTable = (source) => {
  $("#documentTable :ui-fancytree").fancytree("destroy");
  const list = source.length ? JSON.parse(JSON.stringify(source)) : [];
  if (list.length) {
    list.forEach(item => {
      item.children = [];
    });
  }
  $('#documentTable').fancytree('option', 'source', list.length ? list : []);
}

const loadFolderContentTable = () => {
  $("#documentTable").fancytree({
    extensions: ["dnd5", "filter", "grid", "ariagrid"],
    selectMode: 1,
    treeId: "2",
    source: [],
    createNode: function (event, data) {
      const node = data.node;
      const backNode = $.ui.fancytree.getTree("#documentTable").getNodeByKey("id_0000000098e7eb0b");
      if (backNode && backNode.title !== "This folder is empty.") { return; }

      if (node.statusNodeType === "nodata" && data.tree.count() === 1 && selectedFolderKey && selectedFolderKey !== 'root') {
        node.setTitle("[...]");
        node.addClass('previous-folder');
        return;
      }

      if (node.statusNodeType === "nodata") {
        node.addClass('previous-folder');
        return;
      }
      const treeNode = $.ui.fancytree.getTree("#table").getNodeByKey(node.key);
      if (!treeNode) { return; }
      const level = treeNode.getLevel();
      const isFirstSibling = treeNode.isFirstSibling();
      if (level > 2 && isFirstSibling) {
        const newNode = node.addNode({ title: '[...]', folder: false, key: 'id_0000000098e7eb0b', children: [] }, "before");
        newNode.addClass('previous-folder');
      }
    },
    ariagrid: {
      activateCellOnDoubelclick: false,
      cellFocus: 'allow',
      label: "Tree Grid",
    },
    filter: {
      autoExpand: false,
    },
    table: {
      indentation: 20,
      nodeColumnIdx: 1,
    },
    viewport: {
      enabled: true,
      count: 20,
    },
    renderColumns: function (event, data) {
      const node = data.node;
      let $tdList = $(node.tr).find('>td');
      let iconType = 'folder-icon';
      switch (node.type) {
        case 2:
          iconType = 'pdf-icon';
          break;
        case 3:
          iconType = 'image-icon';
          break;
        case 4:
          iconType = 'excel-icon';
          break;
        case 5:
          iconType = 'doc-icon';
          break;
        case 6:
          iconType = 'powerpoint-icon';
          break;
        case 7:
          iconType = 'text-icon';
          break;
        case 8:
          iconType = 'other-icon';
          break;
      }

      $tdList.eq(1).removeClass().addClass(iconType);
      $tdList.eq(2).text(node?.data?.createdDate ?? '').addClass("doc-created-on");
      $tdList.eq(3).text(node?.data?.modifiedByUserName ?? '').addClass("doc-created-by");
      $tdList.eq(4).text(node.type !== 1 && node?.data?.fileSize ? node?.data?.fileSize : '').addClass("doc-size");
    },
    dblclick: (event, data) => {
      const node = data.node;
      if (node) {
        if (node.title === "[...]") {
          let treeNode = $.ui.fancytree.getTree("#table").getNodeByKey(selectedFolderKey).getParent();
          if (!treeNode) {
            return;
          }
          treeNode.setActive();
          treeNode.setExpanded();
          triggerOnChangeEvent('selected-document', { key: treeNode.key });
          return;
        }

        if (node.folder === false || node.title === "This folder is empty.") { return; }

        selectedFolderKey = node.key;
        triggerOnChangeEvent('selected-document', { key: node.key });
        let treeNode = $.ui.fancytree.getTree("#table").getNodeByKey(node.key);
        if (treeNode) {
          let list = treeNode.getParentList(true, true);
          if (list && list.length) {
            for (const item of list) {
              item.setExpanded();
            }
          }
          treeNode.setActive();
        }
      }
    },
    strings:{loading:"Loading...",loadError:"Load error!",moreData:"More...",noData: translations[currentLanguage].nodata},
    dnd5: {
      // --- Drag-support:
      dragStart: function (node, data) {
        data.effectAllowed = "all";
        data.dropEffect = data.dropEffectSuggested;
        return true;
      },
      dragEnter: function (node, data) {
        return true;
      },
      dragOver: function (node, data) {
        data.dropEffect = data.dropEffectSuggested;
      },
      dragDrop: function (node, data) {
        if (data.files && data.files.length) {
          const treeNode = $.ui.fancytree.getTree("#table").getNodeByKey(node.key);
          const level = treeNode.getLevel();
          const key = level === 2 ? null : node?.data?.parentId ?? null;
          const parentNode = treeNode.getParent();
          triggerOnChangeEvent('drag-drop', { key: key, parentId: node?.data?.key ?? null, file: data.files[0], title: parentNode?.title ?? '' });
          return;
        }

        if (data.dropEffect === 'move' && data.hitMode === 'over' && node.folder) {
          triggerOnChangeEvent('move', { key: data.otherNode.key, parentId: node?.key ?? null, selectedDocumentId: selectedFolderKey });
          return;
        }
      },
    },
    lazyLoad: function (event, data) {
      data.result = [];
    },
  });
  loadFolderContentContextMenu();
}

const loadFolderStructureTable = () => {
  $("#table").fancytree({
    extensions: ["dnd5"],
    treeId: "1",
    selectMode: 1,
    source: [],
    init: function (event, data) {
      const root = data.tree.getNodeByKey("root");
      if (root) {
        root.setExpanded();
      }
    },
    activate: (event, data) => {
      const node = data.node;
      if (node) {
        node.setActive();
        node.setExpanded();
        selectedFolderKey = node.key;
        const results = findDocumentsByKey(mainSource, node.key);
        updateFolderContentTable(results && results.length && results[0].children && results[0].children.length ? results[0].children : []);
        triggerOnChangeEvent('selected-document', { key: node.key });
      }

      const tree = data.tree;
      if (!tree) { return; }
      tree.visit((node) => {
        if (node !== data.node && node.folder === true) {
          const hasFolder = node.children && node.children.length ? node.children.some(item => item.folder === true): false;
          if (!hasFolder) {
            $(node.span).removeClass('fancytree-exp-e fancytree-exp-c fancytree-has-children').addClass('fancytree-exp-n');
          }
        }
      });
    },
    renderNode: function (event, data) {
      const node = data.node;
      if (node && node.folder === false) {
        $(node.span).closest('li').addClass('hide');
      }
    }
  });
  loadFolderStructureContextMenu();
}

const loadFolderStructureContextMenu = () => {
  $.contextMenu({
    selector: "#table li",
    build: function(trigger) {
      const node = $.ui.fancytree.getNode(trigger);
      let items = {};
      items.folder = { name: translations[currentLanguage].newFolder, icon: 'fa-folder-open-o' };

      if (canRenameFiles) {
        items.rename = { name: translations[currentLanguage].rename, icon: 'fa-i-cursor' };
      }

      if (canDeleteFiles) {
        items.delete = { name: translations[currentLanguage].delete, icon: 'delete' };
      }

      if (node.key === 'root') {
        items = {
          folder: { name: translations[currentLanguage].newFolder, icon: 'fa-folder-open-o' }
        }
      }

      if (Object.keys(items).length === 0) {
        items = {
          folder: { name: translations[currentLanguage].newFolder, icon: 'fa-folder-open-o', visible: false }
        };
      }
      return {
        items: items,
        callback: function (itemKey, opt) {
          const node = $.ui.fancytree.getNode(opt.$trigger);
          switch (itemKey) {
            case 'rename':
              if (node && node.folder) { triggerOnChangeEvent(itemKey, { title: node.title, key: node?.key ?? null, parentId: node?.data?.parentId ?? null }); }
              break;
            case 'folder':
              if (node && !node.folder) { break; }
              triggerOnChangeEvent(itemKey, { key: node?.key ?? null });
              break;
            case 'delete':
              if (node) { triggerOnChangeEvent(itemKey, { key: node?.key ?? null, selectedDocumentId: selectedFolderKey }); }
              break;
            case 'download':
              if (node) { triggerOnChangeEvent(itemKey, { key: node?.key ?? null, selectedDocumentId: selectedFolderKey }); }
              break;
            case 'file':
              triggerOnChangeEvent(itemKey, { key: node?.key ?? null });
              break;
            default:
              break;
          }
        }
      };
    }
  });
}

const loadFolderContentContextMenu = () => {
  $.contextMenu({
    selector: '#documentTable tr',
    build: function(trigger) {
      const node = $.ui.fancytree.getNode(trigger);
      let items = {};
      if (node && node.folder === true) {
        items.folder = {name: translations[currentLanguage].newFolder, icon: 'fa-folder-open-o'};
      }

      if (canRenameFiles) {
        items.rename = { name: translations[currentLanguage].rename, icon: 'fa-i-cursor' };
      }

      if (canUploadFiles && node && node.folder === false) {
        items.replaceFile = { name: translations[currentLanguage].replaceFile, icon: 'exchange' };
      }

      if (canDownloadFiles && node && node.folder === false) {
        items.download = { name: translations[currentLanguage].download, icon: 'fa-download' };
      }

      if (canDeleteFiles) {
        items.delete = { name: translations[currentLanguage].delete, icon: 'delete' };
      }

      if (node === null) {
        $("#documentTable tr").removeClass('fancytree-focused');
         items = {
          folder: { name: translations[currentLanguage].newFolder, icon: 'fa-folder-open-o' }
        }
      }

      if (Object.keys(items).length === 0) {
        items = {
        folder: { name: translations[currentLanguage].newFolder, icon: 'fa-folder-open-o', visible: false }
        };
      }
      return {
        items: items,
        callback: function (itemKey, opt) {
          const node = $.ui.fancytree.getNode(opt.$trigger);
          switch (itemKey) {
            case 'rename':
              if (node) { triggerOnChangeEvent(itemKey, { title: node.title, key: node?.key ?? null, parentId: node?.data?.parentId ?? null,type: node?.type }); }
              break;
            case 'folder':
              if (node && !node.folder) { break; }
              if (!node) {
                triggerOnChangeEvent(itemKey, { key: selectedFolderKey === 'root' ? null : selectedFolderKey });
                break;
              }
              triggerOnChangeEvent(itemKey, { key: node?.key ?? null });
              break;
            case 'delete':
              if (node) { triggerOnChangeEvent(itemKey, { key: node?.key ?? null, selectedDocumentId: selectedFolderKey, title: node?.title }); }
              break;
            case 'download':
              if (node) { triggerOnChangeEvent(itemKey, { key: node?.key ?? null, selectedDocumentId: selectedFolderKey, title: node?.title, type: node?.type }); }
              break;
            case 'replaceFile':
              triggerOnChangeEvent(itemKey, { key: node?.key ?? null, parentId: node?.data?.parentId ?? null, dataRoomId: node?.data?.dataRoomId ?? null, title: node?.title  });
              break;
            default:
              break;
          }
        }
      };
    }
  });
}

const initializeFileManagerData = (source) => {
  $("#dataRoomTree").fancytree({
    checkbox: false,
    source: [],
    activate: (event, data) => {
      const node = data.node;
      if (node) {
        const level = node.getLevel();
        const parentId = level === 1 ? node.key : node?.data?.dataRoomId ?? null;
        triggerOnChangeEvent('selected-document', {key: node.key, parentId: parentId, title: node?.title ?? ''});
      }

      hideNodeChildren(data);
    },
    renderNode: function (event, data) {
      const node = data.node;
      if (node && node.folder === false) {
        $(node.span).closest('li').addClass('hide-nodes');
      }

      hideNodeChildren(data);
    }
  });
}

const hideNodeChildren = (data) => {
  const dataRoomTree = data.tree;
  if (!dataRoomTree) { return; }
  dataRoomTree.visit((obj) => {
    if (obj.folder === true){
      const hasFolder = obj.children && obj.children.length ? obj.children.some(item => item.folder === true): false;
      if (!hasFolder) {
        $(obj.span).addClass('fancytree-node-hide');
      }
    }
  });
}

const updateFileManagerData = (source, selectedFolderId) => {
  $("#dataRoomTree :ui-fancytree").fancytree("destroy");
  $('#dataRoomTree').fancytree('option', 'source', source.length ? source : []);

  if (selectedFolderId && selectedFolderId !== 'root') {
    let selectedNode = $.ui.fancytree.getTree("#dataRoomTree").getNodeByKey(selectedFolderId);
    if (!selectedNode) { return; }
    selectedNode.setActive();
    selectedNode.setExpanded();
    return;
  }

  const nodes = $("#dataRoomTree").fancytree('getRootNode').getChildren();
  if (!nodes || !nodes.length) {
    return;
  }
  nodes[0].setActive();
  nodes[0].setExpanded();
}

const loadUploadPopup = () => {
  $('#btnOpenPopup').off('click');
  $("#btnOpenPopup").click(function () {
    const node = $("#table").fancytree("getActiveNode");
    triggerOnChangeEvent('upload-file', { key: node?.key ?? null, title: node?.title ?? '' });
  });
}

const changeSelection = (key) => {
  let node = $.ui.fancytree.getTree("#table").getNodeByKey(key);
  if (!node) {
    return;
  }
  node.setActive();
  node.setExpanded();
  selectedFolderKey = node.key;
  const results = findDocumentsByKey(mainSource, node.key);
  updateFolderContentTable(results && results.length && results[0].children && results[0].children.length ? results[0].children : []);
}

const findDocumentsByKey = (list, keyToSearch) => {
  let result = [];
  for (const item of list) {
    if (item.key === keyToSearch) {
      result.push(item);
    }

    if (item.children) {
      const childResults = findDocumentsByKey(item.children, keyToSearch);
      result = result.concat(childResults);
    }
  }
  return result;
}

const filterByFolderType = (list) => {
  return list.reduce((filtered, item) => {
    if (item.type === 1) {
      if (item.children && item.children.length) {
        item.children = filterByFolderType(item.children);
      }
      filtered.push(item);
    } else if (item.children && item.children.length) {
      const filteredChildren = filterByFolderType(item.children);
      if (filteredChildren && filteredChildren.length) {
        item.children = filteredChildren;
        filtered.push(item);
      }
    }
    return filtered;
  }, []);
}
